@font-face { font-family: Wonder; src: url('./assets/fonts/wonder.ttf'); }

* {
  box-sizing: border-box;
}

:root {
  --width: 200px;
  --elheight: 300px;
  --leheight: 300px;
  --x-speed: 13s;
  --y-speed: 7s;
  --w-speed: 8s;
  --z-speed: 4s;
  --transition-speed: 2.2s;
}

.bounce {
  position:fixed;
  opacity: 100%;
  z-index: 3;
}

.bounce1 {
  position: fixed;
  opacity: 100%;
  z-index: 2;
}

.el { 
  width: var(--width);
  height: var(--elheight);
  border-radius: 15px;
}

.le { 
  width: var(--width);
  height: var(--leheight);
  border-radius: 15px;
}

.x {
  animation: x var(--x-speed) linear infinite alternate;
}
.y {
  animation: y var(--y-speed) linear infinite alternate;
}

.w {
  animation: x var(--w-speed) linear infinite alternate;
}

.z {
  animation: y var(--z-speed) linear infinite alternate;
}

@keyframes x {
  100% {
    transform: translateX(calc(100vw - var(--width)));
  }
}
@keyframes y {
  100% {
    transform: translateY(calc(500% - var(--width)));
  }
}

.App {
  text-align: center;
  position: absolute;
  font-size: 20px;
  letter-spacing: 1px;
  font-size: 30px;
  background-image: url("./assets/bck.gif");
  background-repeat: no-repeat;
  background-size:cover;
  background-attachment: scroll;
  background-position: center;
  width: 100%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px; /* Or whatever height you want */
}

.cn {
  font-family: ""Microsoft YaHei",",sans-serif;
  font-variation-settings: 'wght' 100;
  color: white;
  
}

.bold {
  font-weight: 600;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.item {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 6%;
  margin-right: 6%;
}

h1 {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif, sans-serif;
  font-weight: 1;
  font-size: 70px;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: black;
  color: #ffff
}

.title {
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif, sans-serif;
  font-weight: 1;
  font-size: 35px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  color: #ffffff
}

.main {
  font-size: 50px;
  margin: 50px 100px;
  font-family: Wonder, sans-serif;
  color: #ffffff;
}

.main2 {
  font-size: 50px;
  margin: 50px 100px;
  font-family: Wonder, sans-serif;
  color: #ffffff;
}

.top {
  font-size: 120px;
  width: 100%;
}

.bottom {
  -webkit-text-stroke-width: 2.5px;
  -webkit-text-stroke-color: black;
}

.App-logo {
  border-radius: 20px;
}

.front {
  z-index: 10;
  position: relative;
}

@font-face {
  font-family: fairy;
  src: url(./assets/fonts/fairy.ttf)
}

.fairy {
  font-family: fairy;
  color: #ffffff;
}

.about {
  margin: 75px;
}

.space {
  height: 70px;
}

.vid {
  width: 40vw;
  border-radius: 15px;
}

.no-pointer {
  pointer-events: none;
}