/* Styles for the entire modal container */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./assets/bck.gif");
    background-size: cover;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  /* Styles for the modal content */
  .modal-content {
    -webkit-text-stroke: 6px black;
    border-radius: 20px;
    max-width: 80%;
    text-align: center;
    position: relative;
    cursor: pointer;
  }

  .text {
    font-size: 200px;
    margin: 10px 20px;
    font-family: Wonder, sans-serif;
    color: #ffffff;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif, sans-serif;
  }

  .text:hover {
    color: #dbdbdb;
  }